export enum ArrangementType {
  Unknown = 'Unknown',
  Transfer = 'Transfer',
  DollarCostAveraging = 'DollarCostAveraging',
  AssetRebalancing = 'AssetRebalancing',
  ReviewPayoutPercentage = 'ReviewPayoutPercentage',
  InitialPayment = 'InitialPayment',
  AssetAllocationModel = 'AssetAllocationModel',
  AutoPaymentBankDraftingProgram = 'AutoPaymentBankDraftingProgram',
  SpecifiedAmountDeposit = 'SpecifiedAmountDeposit',
  FullSurrender = 'FullSurrender',
  AccountValueAdjustment = 'AccountValueAdjustment',
  StandingAllocation = 'StandingAllocation',
  SystematicWithdrawal = 'SystematicWithdrawal',
  SubsequentPayment = 'SubsequentPayment',
  Loans = 'Loans',
  CapitalizedLoanInterest = 'CapitalizedLoanInterest',
  ChargeDeduction = 'ChargeDeduction',
  AllocationInstructions = 'AllocationInstructions',
  PremiumIncrease = 'PremiumIncrease',
  CoverIncrease = 'CoverIncrease',
  OneTimeWithdrawal = 'OneTimeWithdrawal',
  Other = 'Other'
}

export enum ArrangementTypeId {
  DollarCostAveraging = 2,
  AssetRebalancing = 3,
  InitialPremium = 19,
  AssetAllocationModel = 21
}

export enum CompensationType {
  Fee = 'fee',
  Commission = 'commission'
}

export enum CompensationTypeId {
  Fee = 1,
  Commission = 2
}

export enum ChargeBasedOn {
  Unknown = 'Unknown',
  GrossPremium = 'GrossPremium',
  AccountValue = 'AccountValue',
  GreaterofPremiumorAccountValue = 'GreaterofPremiumorAccountValue',
  NetPremium = 'NetPremium',
  Netamountatrisk = 'Netamountatrisk',
  Benefitamount = 'Benefitamount',
  Waivedbasedonfaceamount = 'Waivedbasedonfaceamount',
  PerUnitBasis = 'PerUnitBasis',
  CumulativePremium = 'CumulativePremium',
  DurationPremium = 'DurationPremium',
  CostofInsurance = 'CostofInsurance',
  AccountValueplusgainlessBonus = 'AccountValueplusgainlessBonus',
  GreaterofFixedorSeparateAccount = 'GreaterofFixedorSeparateAccount',
  WithdrawalAmount = 'WithdrawalAmount',
  FederalTaxWithholding = 'FederalTaxWithholding',
  GreaterofBenefitAmountandAccountValue = 'GreaterofBenefitAmountandAccountValue',
  VariableAccountValue = 'VariableAccountValue',
  NetAssetValue = 'NetAssetValue',
  Other = 'Other'
}

export enum ContextType {
  Cusip = 'Cusip',
  RiderKey = 'RiderKey',
  Subaccount = 'Subaccount',
  RiderRuleGroup = 'RiderRuleGroup'
}

export enum ContractDateType {
  IssueDate = '092',
  MaturityDate = '917'
}

export enum ContractPartyType {
  Owner = 'HA',
  OwnerAnnuitantOrInsured = 'OK'
}

export enum ContractValuationType {
  ContractValue = 'CR',
  NetDeathBenefit = 'DE',
  TotalPremium = 'TP'
}

export enum ContractRecommendationStrengthType {
  High = 'High',
  Low = 'Low',
  Moderate = 'Moderate'
}

export enum DataSource {
  Ppfa = 'Ppfa',
  Morningstar = 'Morningstar'
}

export enum FeeType {
  Unknown = 'Unknown',
  PolicyFee = 'PolicyFee',
  AdministrativeFee = 'AdministrativeFee',
  MarketingFee12b1 = 'MarketingFee12b1',
  MortalityandExpenseFee = 'MortalityandExpenseFee',
  FundLevelFee = 'FundLevelFee',
  FundLevelOtherAssetBasedFees = 'FundLevelOtherAssetBasedFees',
  MortalityCharge = 'MortalityCharge',
  Expensecharge = 'Expensecharge',
  FundManagementFee = 'FundManagementFee',
  CollectionFee = 'CollectionFee',
  LicenseFee = 'LicenseFee',
  AppointmentFee = 'AppointmentFee',
  SalesLoad = 'SalesLoad',
  ExchangeorTransferFee = 'ExchangeorTransferFee',
  WithdrawalFee = 'WithdrawalFee',
  SurrenderFee = 'SurrenderFee',
  PremiumTaxSurrender = 'PremiumTaxSurrender',
  PremiumTax = 'PremiumTax',
  TransactionFee = 'TransactionFee',
  FederalTax = 'FederalTax',
  StateTax = 'StateTax',
  PremiumLoad = 'PremiumLoad',
  PackageDiscountFeePercent = 'PackageDiscountFeePercent',
  SalesFee = 'SalesFee',
  Lapsefee = 'Lapsefee',
  FaceIncreaseFee = 'FaceIncreaseFee',
  FaceDecreaseFee = 'FaceDecreaseFee',
  ChangeDeathBenefitOptionFee = 'ChangeDeathBenefitOptionFee',
  BaseMandECharge = 'BaseMandECharge',
  RiderMEChargesTotal = 'RiderMEChargesTotal',
  RedemptionFee = 'RedemptionFee',
  FeatureRiderLevelMortalityandExpenseFee = 'FeatureRiderLevelMortalityandExpenseFee',
  TotalBaseFeePercentage = 'TotalBaseFeePercentage',
  PolicyMarketingandAdministrativeFee = 'PolicyMarketingandAdministrativeFee',
  PolicyDistributionFee = 'PolicyDistributionFee',
  FuelSurcharge = 'FuelSurcharge',
  MedicalRecordsFee = 'MedicalRecordsFee',
  ServicesRendered = 'ServicesRendered',
  CancellationFee = 'CancellationFee',
  NoRecordsFee = 'NoRecordsFee',
  SpecialAuthorizationFee = 'SpecialAuthorizationFee',
  FastTrack = 'FastTrack',
  OvernightShippingorMailingFee = 'OvernightShippingorMailingFee',
  LocalTax = 'LocalTax',
  RecordkeepingFee = 'RecordkeepingFee',
  SiteFee = 'SiteFee',
  FeatureRiderBenefitChargeFee = 'FeatureRiderBenefitChargeFee',
  MileageFee = 'MileageFee',
  ParkingFee = 'ParkingFee',
  TollRoadCharge = 'TollRoadCharge',
  FerryCharges = 'FerryCharges',
  LongDistancePhoneCharge = 'LongDistancePhoneCharge',
  FaxFee = 'FaxFee',
  PhotocopyingFee = 'PhotocopyingFee',
  ScanningFee = 'ScanningFee',
  SetupFee = 'SetupFee',
  SpecialHandlingFeeVIPhandling = 'SpecialHandlingFeeVIPhandling',
  TranslationFee = 'TranslationFee',
  ElectronicOrderingUsageFee = 'ElectronicOrderingUsageFee',
  RuralServiceFee = 'RuralServiceFee',
  Noshowfee = 'Noshowfee',
  RiderReductionAdjustmentFee = 'RiderReductionAdjustmentFee',
  NetFundManagementFee = 'NetFundManagementFee',
  Other = 'Other'
}

export enum FilterCodes {
  AnnuityAnnuitantAge = 'ANNUITY_ANNUITANT_AGE',
  AnnuityAnnuitantRelationshipType = 'ANNUITY_ANNUITANT_RELATIONSHIP_TYPE',
  AnnuityBeneficiaryAge = 'ANNUITY_BENEFICIARY_AGE',
  AnnuityBeneficiaryRelationshipType = 'ANNUITY_BENEFICIARY_RELATIONSHIP_TYPE',
  AnnuityBenefitType = 'ANNUITY_BENEFIT_TYPE',
  AnnuityCompensationType = 'ANNUITY_COMPENSATION_TYPE',
  AnnuityInsuranceCompany = 'ANNUITY_INSURANCE_COMPANY',
  AnnuityJointAnnuitantAge = 'ANNUITY_JOINT_ANNUITANT_AGE',
  AnnuityJointAnnuitantRelationshipType = 'ANNUITY_JOINT_ANNUITANT_RELATIONSHIP_TYPE',
  AnnuityJointOwnerAge = 'ANNUITY_JOINT_OWNER_AGE',
  AnnuityJointOwnerRelationshipType = 'ANNUITY_JOINT_OWNER_RELATIONSHIP_TYPE',
  AnnuityJurisdiction = 'ANNUITY_JURISDICTION',
  AnnuityOwnerAge = 'ANNUITY_OWNER_AGE',
  AnnuityOwnershipType = 'ANNUITY_OWNERSHIP_TYPE',
  AnnuityPremium = 'ANNUITY_PREMIUM',
  AnnuityPrimaryOwner = 'ANNUITY_PRIMARY_OWNER',
  AnnuityTaxQualification = 'ANNUITY_TAX_QUALIFICATION',
  AnnuityQualifiedPlanType = 'ANNUITY_QUALIFIED_PLAN_TYPE',
  AnnuityType = 'ANNUITY_TYPE',
  CarrierProperty = 'CARRIER_PROPERTY',
  FirmDtccMemberCode = 'FIRM_DTCCMEMBERCODE',
  ServicedFirmDtccMemberCode = 'SERVICED_FIRM_DTCCMEMBERCODE',
  FirmType = 'FIRM_TYPE',
  HasJointAnnuitant = 'HAS_JOINT_ANNUITANT',
  KeywordSearch = 'KEYWORD_SEARCH',
  MorningstarId = 'MORNINGSTAR_ID',
  OwnerAnnuitantAge = 'OWNER_ANNUITANT_AGE',
  OwnerAnnuitantIsSame = 'OWNER_ANNUITANT_IS_SAME',
  OwnershipTypeIndividual = 'INDIVIDUAL',
  OwnershipTypeJoint = 'JOINT',
  PlatformCode = 'PLATFORM_CODE',
  ProductProperty = 'PRODUCT_PROPERTY',
  RiderKey = 'RIDER_KEY',
  RiderType = 'RIDER_TYPE',
  RiskScore = 'RISK_SCORE',
  SectionGroupType = 'SECTION_GROUP_TYPE',
  SubaccountKey = 'SUBACCOUNT_KEY',
  SubaccountProductCode = 'SUBACCOUNT_PRODUCT_CODE',
  MorningstarInsuranceCompany = 'MORNINGSTAR_INSURANCE_COMPANY',
  FilterByDiscretion = 'FILTER_BY_DISCRETION'
}

export enum GroupType {
  RiderSection = 'RiderSection',
  RiderGroupOfGroups = 'RiderGroupOfGroups',
  RiderGroup = 'RiderGroup',
  RiderGroupRider = 'RiderGroupRider',
  SubaccountSection = 'SubaccountSection',
  SubaccountGroupOfGroups = 'SubaccountGroupOfGroups',
  SubaccountGroup = 'SubaccountGroup',
  SubaccountGroupSubaccount = 'SubaccountGroupSubaccount',
  RiderSubaccountSection = 'RiderSubaccountSection',
  RiderSubaccountGroupOfGroups = 'RiderSubaccountGroupOfGroups',
  RiderSubaccountGroup = 'RiderSubaccountGroup',
  RiderSubaccountGroupSubaccount = 'RiderSubaccountGroupSubaccount',
  ProductOverlaySection = 'ProductOverlaySection',
  ProductOverlayGroupOfGroups = 'ProductOverlayGroupOfGroups',
  ProductOverlay = 'ProductOverlay',
  ProductOverlaySubaccountSection = 'ProductOverlaySubaccountSection',
  ProductOverlaySubaccountGroupOfGroups = 'ProductOverlaySubaccountGroupOfGroups',
  ProductOverlaySubaccountGroup = 'ProductOverlaySubaccountGroup',
  ProductOverlaySubaccountGroupSubaccount = 'ProductOverlaySubaccountGroupSubaccount',
  ProgramOptionSection = 'ProgramOptionSection',
  ProgramOptionGroupOfGroups = 'ProgramOptionGroupOfGroups',
  ProgramOptionGroup = 'ProgramOptionGroup',
  ProgramOptionGroupProgramOption = 'ProgramOptionGroupProgramOption',
  SourceSubaccountSection = 'SourceSubaccountSection',
  SourceSubaccountGroupOfGroups = 'SourceSubaccountGroupOfGroups',
  SourceSubaccountGroup = 'SourceSubaccountGroup',
  SourceSubaccountGroupSubaccount = 'SourceSubaccountGroupSubaccount',
  DestinationSubaccountSection = 'DestinationSubaccountSection',
  DestinationSubaccountGroupOfGroups = 'DestinationSubaccountGroupOfGroups',
  DestinationSubaccountGroup = 'DestinationSubaccountGroup',
  DestinationSubaccountGroupSubaccount = 'DestinationSubaccountGroupSubaccount'
}

export enum IllustrationType {
  Static = 'Static',
  Generated = 'Generated'
}

export enum MorningstarDescriptionType {
  Transfer = 'Transfer',
  FreeWithdrawal = 'FreeWithdrawal',
  SystematicWithdrawal = 'SystematicWithdrawal',
  AdministrativeFee = 'AdministrativeFee',
  SurrenderFee = 'SurrenderFee',
  MAndERiskCharge = 'MAndERiskCharge',
  InvestmentRequirement = 'InvestmentRequirement',
  IssueAge = 'IssueAge',
  PolicyPurchaseAvailability = 'PolicyPurchaseAvailability',
  FrontSalesCharge = 'FrontSalesCharge',
  Loan = 'Loan'
}

export enum ProductOverlayStrictnessLevel {
  Strict = 'Strict',
  Relaxed = 'Relaxed'
}

export enum ProductPropertyType {
  AnnuityType = 'ANNUITY_TYPE',
  CompensationType = 'COMPENSATION_TYPE',
  ProductImage = 'PRODUCT_IMAGE',
  ProductDescriptionFormatted = 'PRODUCT_DESCRIPTION_FORMATTED'
}

export enum ProductSortType {
  ProductName = 'ProductName',
  ProductNameDescending = 'ProductNameDescending',
  CarrierName = 'CarrierName',
  CarrierNameDescending = 'CarrierNameDescending',
  Favorites = 'Favorites'
}

export enum ProposalCaseStatusType {
  AccountOpeningReady = 'AccountOpeningReady',
  AppCancelled = 'AppCancelled',
  AppDataEntry = 'AppDataEntry',
  AppInReview = 'AppInReview',
  AppInSignatures = 'AppInSignatures',
  AppPendingReview = 'AppPendingReview',
  AppRejected = 'AppRejected',
  AppRejectedInReview = 'AppRejectedInReview',
  AppSentToReview = 'AppSentToReview',
  AppSubmitted = 'AppSubmitted',
  AppSubmittedToCarrier = 'AppSubmittedToCarrier',
  Assigned = 'Assigned',
  Cancelled = 'Cancelled',
  FinalIllustrationRejected = 'FinalIllustrationRejected',
  IllustrationInProgress = 'IllustrationInProgress',
  IllustrationMarkedFinal = 'IllustrationMarkedFinal',
  IllustrationRequestCancelled = 'IllustrationRequestCancelled',
  IllustrationSubmittedToAdvisor = 'IllustrationSubmittedToAdvisor',
  New = 'New',
  ReadyForSignatures = 'ReadyForSignatures',
  Rejected = 'Rejected',
  SignaturesComplete = 'SignaturesComplete',
  SubmittedToAdvisorForApproval = 'SubmittedToAdvisorForApproval'
}

export enum ProposalCaseType {
  Imo = 'Imo',
  Oid = 'Oid'
}

export enum ProposalStatusType {
  ProductSelection = 'ProductSelection',
  Illustration = 'Illustration',
  AccountOpening = 'AccountOpening'
}

export enum RiderSubType {
  Unknown = 'Unknown',
  BeneficiaryProtectionRider = 'BeneficiaryProtectionRider',
  SpousalProtectionBenefit = 'SpousalProtectionBenefit',
  Growth = 'Growth',
  SteppedUp = 'SteppedUp',
  ReturnofPremium = 'ReturnofPremium',
  Returnofaccountvalue = 'Returnofaccountvalue',
  StandardGMAB = 'StandardGMAB',
  StandardGMIB = 'StandardGMIB',
  GuaranteedPaymentFloorStepUp = 'GuaranteedPaymentFloorStepUp',
  StandardGMWB = 'StandardGMWB',
  StandardGuaranteedWithdrawalforLifeBenefit = 'StandardGuaranteedWithdrawalforLifeBenefit',
  NursingHomeGuaranteedWithdrawalforLife = 'NursingHomeGuaranteedWithdrawalforLife',
  PremiumEnhancement = 'PremiumEnhancement',
  CreditInterestToAmount = 'CreditInterestToAmount',
  NursingHomeRider = 'NursingHomeRider',
  TerminalIllnessRider = 'TerminalIllnessRider',
  HardshipRider = 'HardshipRider',
  DisabilityRider = 'DisabilityRider',
  LShare = 'LShare',
  LongTermCare = 'LongTermCare',
  ImpairedRiskOption = 'ImpairedRiskOption',
  SpousalContinuationBenefit = 'SpousalContinuationBenefit',
  CommutationOption = 'CommutationOption',
  PreSelectedDeathBenefitOption = 'PreSelectedDeathBenefitOption',
  JointAnnuitantCharge = 'JointAnnuitantCharge',
  StandardDeathBenefit = 'StandardDeathBenefit',
  AShare = 'AShare',
  BShare = 'BShare',
  Bonus = 'Bonus',
  CShare = 'CShare',
  AutomaticAnnualStepUp = 'AutomaticAnnualStepUp',
  SteppedUpGMWBforLife = 'SteppedUpGMWBforLife',
  SteppedUpGMWB = 'SteppedUpGMWB',
  FullAccessNoSurrenderCharge = 'FullAccessNoSurrenderCharge',
  RateProtectionBenefit = 'RateProtectionBenefit',
  WaiverofReturnofPremium = 'WaiverofReturnofPremium',
  ReturnofPremiumGuarantee = 'ReturnofPremiumGuarantee',
  InterestCreditingOption = 'InterestCreditingOption',
  ChargeFreeWithdrawal = 'ChargeFreeWithdrawal',
  ReducedMinimumPurchase = 'ReducedMinimumPurchase',
  NoShareClass = 'NoShareClass',
  FixedSingleLifePayout = 'FixedSingleLifePayout',
  AnnuallyLevelizedIncomePayout = 'AnnuallyLevelizedIncomePayout',
  InflationAdjustedIncomePayout = 'InflationAdjustedIncomePayout',
  MinimumVariablePayoutGuarantee = 'MinimumVariablePayoutGuarantee',
  ReturnofPremiumLessFees = 'ReturnofPremiumLessFees',
  FixedJointLifePayout = 'FixedJointLifePayout',
  ReturnofPremiumDeathPartial = 'ReturnofPremiumDeathPartial',
  ReturnofPremiumDeathFull = 'ReturnofPremiumDeathFull',
  ReturnofPremiumFull = 'ReturnofPremiumFull',
  JointLifeProtectionBenefit = 'JointLifeProtectionBenefit',
  JointSurvivorContinuationBenefit = 'JointSurvivorContinuationBenefit',
  PrincipalGuarantee = 'PrincipalGuarantee',
  RestorationofBenefitsFull = 'RestorationofBenefitsFull',
  RestorationofBenefitsPartial = 'RestorationofBenefitsPartial',
  DeferredIncomeAnnuityRider = 'DeferredIncomeAnnuityRider',
  RollUpGuaranteedWithdrawalforLifeBenefit = 'RollUpGuaranteedWithdrawalforLifeBenefit',
  IShare = 'IShare',
  OShare = 'OShare',
  MarketValueAdjustmentMVAWaiver = 'MarketValueAdjustmentMVAWaiver',
  ReducedSurrenderChargeSchedule = 'ReducedSurrenderChargeSchedule',
  Other = 'Other'
}

export enum RiderType {
  Unknown = 'Unknown',
  CostofLivingAdjustmentRiderCOLA = 'CostofLivingAdjustmentRiderCOLA',
  Exclusion = 'Exclusion',
  FIOfutureincomeoption = 'FIOfutureincomeoption',
  GIRGuaranteedinsurancerider = 'GIRGuaranteedinsurancerider',
  GMRGuaranteedMedicalRider = 'GMRGuaranteedMedicalRider',
  GMIGuaranteedMedicalIns = 'GMIGuaranteedMedicalIns',
  Maternity = 'Maternity',
  HospitalIndemnity = 'HospitalIndemnity',
  Surgical = 'Surgical',
  SocialInsuranceSupplement = 'SocialInsuranceSupplement',
  AutomaticIncomeBenefit = 'AutomaticIncomeBenefit',
  OptiontoPurchaseAdditionalInsuranceRider = 'OptiontoPurchaseAdditionalInsuranceRider',
  Physicians = 'Physicians',
  Vision = 'Vision',
  Dental = 'Dental',
  Prescription = 'Prescription',
  Longtermcare = 'Longtermcare',
  Hospitalsurgical = 'Hospitalsurgical',
  LongTermFacilityCareService = 'LongTermFacilityCareService',
  Medicaresupplement = 'Medicaresupplement',
  DreadDisease = 'DreadDisease',
  IncomeProtection = 'IncomeProtection',
  Medicalexpensecoverageavailableforfamilymembers = 'Medicalexpensecoverageavailableforfamilymembers',
  Medicalexpenseinsuredonly = 'Medicalexpenseinsuredonly',
  Accidentaldeathanddismemberment = 'Accidentaldeathanddismemberment',
  Commerciallossoftimeincludesfranchise = 'Commerciallossoftimeincludesfranchise',
  Noncancelableincomedisability = 'Noncancelableincomedisability',
  MiscellaneousRoomBoard = 'MiscellaneousRoomBoard',
  PrivateNursing = 'PrivateNursing',
  MedicalSurgicalExpense = 'MedicalSurgicalExpense',
  PrivateDutyNursing = 'PrivateDutyNursing',
  IntensiveCareUnit = 'IntensiveCareUnit',
  MedicalServicesandSupplies = 'MedicalServicesandSupplies',
  Deductiblebuydown = 'Deductiblebuydown',
  ExtraHospitalMiscellaneousExpenses = 'ExtraHospitalMiscellaneousExpenses',
  WellChildCare = 'WellChildCare',
  ConvalescentIncome = 'ConvalescentIncome',
  MedicareExtended = 'MedicareExtended',
  MonthlyHospitalConfinement = 'MonthlyHospitalConfinement',
  LongTermCareandCommunityBasedCareService = 'LongTermCareandCommunityBasedCareService',
  WaiverofEliminationPeriodknownasWaiverofQualifyingPeriodinAustralia = 'WaiverofEliminationPeriodknownasWaiverofQualifyingPeriodinAustralia',
  AmbulanceCoverage = 'AmbulanceCoverage',
  PrivateHospitalRoom = 'PrivateHospitalRoom',
  LossSpeechandHearing = 'LossSpeechandHearing',
  BloodPlasmaandTransfusions = 'BloodPlasmaandTransfusions',
  AlcoholismDrugAddiction = 'AlcoholismDrugAddiction',
  MentalIllness = 'MentalIllness',
  AccidentInjury = 'AccidentInjury',
  FutureIncreaseOption = 'FutureIncreaseOption',
  MedicareHospitalIndemnity = 'MedicareHospitalIndemnity',
  PartialDisability = 'PartialDisability',
  WorkmensCompensationOffset = 'WorkmensCompensationOffset',
  AdditionalMonthlyIndemnity = 'AdditionalMonthlyIndemnity',
  AggregateIncreaseProtection = 'AggregateIncreaseProtection',
  CapitalAccount = 'CapitalAccount',
  CostofLivingCPIInflationOption = 'CostofLivingCPIInflationOption',
  CostofLiving = 'CostofLiving',
  Guar = 'Guar',
  FamilyIncome = 'FamilyIncome',
  FixedIncome = 'FixedIncome',
  Homemaker = 'Homemaker',
  HospitalIncome = 'HospitalIncome',
  InstallmentSchedule = 'InstallmentSchedule',
  LifetimeAccident = 'LifetimeAccident',
  LifetimeTotalDisabilityknownasTotalandPermanentDisabilityinAustralia = 'LifetimeTotalDisabilityknownasTotalandPermanentDisabilityinAustralia',
  CashBenefitLumpSumPayout = 'CashBenefitLumpSumPayout',
  NonOccupationAMI = 'NonOccupationAMI',
  OptiontoPurchaseResidualBenefit = 'OptiontoPurchaseResidualBenefit',
  OptionalResidualBenefit = 'OptionalResidualBenefit',
  ReturnofPremiumNonForfeitureFullDeath = 'ReturnofPremiumNonForfeitureFullDeath',
  PrincipalSum = 'PrincipalSum',
  RedElimHospConf = 'RedElimHospConf',
  ReplacementExpense = 'ReplacementExpense',
  ResidualDisability = 'ResidualDisability',
  ResidualLongTerm = 'ResidualLongTerm',
  ResidualShortTerm = 'ResidualShortTerm',
  SalaryReplacement = 'SalaryReplacement',
  SocialSecurityIncome = 'SocialSecurityIncome',
  SupplementalIncome = 'SupplementalIncome',
  TDYourOccupation = 'TDYourOccupation',
  AIDSConditional = 'AIDSConditional',
  AIDSUnconditionalfullcoverageNoconditionsoncontractionofillness = 'AIDSUnconditionalfullcoverageNoconditionsoncontractionofillness',
  Employmentinfirm = 'Employmentinfirm',
  Automaticbenefitincrease = 'Automaticbenefitincrease',
  Benefitupdateagreement = 'Benefitupdateagreement',
  Returntoworkstillcoveredifyoureturntoworkbutnotinsamelevelposition = 'Returntoworkstillcoveredifyoureturntoworkbutnotinsamelevelposition',
  RegularOccupationstillcoveredifyoureturntoworkbutindifferentoccupation = 'RegularOccupationstillcoveredifyoureturntoworkbutindifferentoccupation',
  Waiverofpremiumupondisability = 'Waiverofpremiumupondisability',
  Overheadexpense = 'Overheadexpense',
  Funeralexpense = 'Funeralexpense',
  AdditionalClaimBenefit = 'AdditionalClaimBenefit',
  SelectOccupationPremiumDiscount = 'SelectOccupationPremiumDiscount',
  TaxReturnPremiumDiscount = 'TaxReturnPremiumDiscount',
  EmployerPremiumDiscount = 'EmployerPremiumDiscount',
  AssociationPremiumDiscount = 'AssociationPremiumDiscount',
  MultiplePolicyDiscount = 'MultiplePolicyDiscount',
  AccidentExtendedWait = 'AccidentExtendedWait',
  AccidentSicknessExtendedWait = 'AccidentSicknessExtendedWait',
  Accident1stDayCoverage = 'Accident1stDayCoverage',
  ExtendedEliminationPeriod = 'ExtendedEliminationPeriod',
  NonSmokerDiscountRider = 'NonSmokerDiscountRider',
  ForeignTravelRider = 'ForeignTravelRider',
  ReturnofPremiumTerm = 'ReturnofPremiumTerm',
  SurrenderChargeWaiver = 'SurrenderChargeWaiver',
  GuaranteedMinimumIncomeBenefitGMIB = 'GuaranteedMinimumIncomeBenefitGMIB',
  DeathBenefit = 'DeathBenefit',
  SurrenderCharges = 'SurrenderCharges',
  ExtendedGuaranteePeriod = 'ExtendedGuaranteePeriod',
  GuaranteedMinimumAccumulationBenefitByYears = 'GuaranteedMinimumAccumulationBenefitByYears',
  Bonus = 'Bonus',
  HealthInsuranceRider = 'HealthInsuranceRider',
  AdministrativeRider = 'AdministrativeRider',
  GuaranteedMinWithdrawalforLifeBenefitGWLB = 'GuaranteedMinWithdrawalforLifeBenefitGWLB',
  InterestRateOptions = 'InterestRateOptions',
  BenefitPeriodEnhancementRider = 'BenefitPeriodEnhancementRider',
  UnemploymentRider = 'UnemploymentRider',
  LongTermComprehensiveCare = 'LongTermComprehensiveCare',
  ReturnofPremium = 'ReturnofPremium',
  NonForfeitureRestorationOfBenefits = 'NonForfeitureRestorationOfBenefits',
  PaidupSurvivorBenefit = 'PaidupSurvivorBenefit',
  IndemnityFacility = 'IndemnityFacility',
  IndemnityHomeandCommunityBasedCarePctDailyBenefit = 'IndemnityHomeandCommunityBasedCarePctDailyBenefit',
  PrePayDiscount = 'PrePayDiscount',
  FamilyCareGiverReimbursement = 'FamilyCareGiverReimbursement',
  CareGiverReimbursement = 'CareGiverReimbursement',
  RestorationofBenefits = 'RestorationofBenefits',
  WaiverofPremiumSpousal = 'WaiverofPremiumSpousal',
  CostofLivingCompound = 'CostofLivingCompound',
  CostofLivingSimple = 'CostofLivingSimple',
  SpousalDiscount = 'SpousalDiscount',
  AffiliationDiscount = 'AffiliationDiscount',
  SelectHealthDiscount = 'SelectHealthDiscount',
  IndemnityHomeandCommunityBasedCareAmt = 'IndemnityHomeandCommunityBasedCareAmt',
  QualifiedAdultDiscount = 'QualifiedAdultDiscount',
  SharedBenefitRider = 'SharedBenefitRider',
  InflationCalculationRider = 'InflationCalculationRider',
  ResidualDisabilityBenefitRider = 'ResidualDisabilityBenefitRider',
  AutomaticIncreaseRider = 'AutomaticIncreaseRider',
  SocialInsuranceSubstituteBenefitsRider = 'SocialInsuranceSubstituteBenefitsRider',
  ActivitiesofDailyLivingIncomeBenefitRider = 'ActivitiesofDailyLivingIncomeBenefitRider',
  GuaranteedMinimumWithdrawalBenefitGMWB = 'GuaranteedMinimumWithdrawalBenefitGMWB',
  AssistedLivingCare = 'AssistedLivingCare',
  BedReservation = 'BedReservation',
  PhysicalTherapy = 'PhysicalTherapy',
  SpeechTherapy = 'SpeechTherapy',
  OccupationalTherapy = 'OccupationalTherapy',
  DayCare = 'DayCare',
  AdultFosterCare = 'AdultFosterCare',
  RespiteCare = 'RespiteCare',
  HospiceCare = 'HospiceCare',
  AlternatePlanofCare = 'AlternatePlanofCare',
  Rehabilitation = 'Rehabilitation',
  SurgicalTransplantDonor = 'SurgicalTransplantDonor',
  NonDisablingInjury = 'NonDisablingInjury',
  DisabilityBuyOut = 'DisabilityBuyOut',
  SicknessDisabilityIncome = 'SicknessDisabilityIncome',
  AccidentDisabilityIncome = 'AccidentDisabilityIncome',
  BasicHospital = 'BasicHospital',
  DentalPlusOrthodontia = 'DentalPlusOrthodontia',
  DreadDiseaseandImpairment = 'DreadDiseaseandImpairment',
  SmallBusinessDiscount = 'SmallBusinessDiscount',
  LoyaltyDiscount = 'LoyaltyDiscount',
  PremiumRateGuarantee = 'PremiumRateGuarantee',
  FamilyCareRider = 'FamilyCareRider',
  NonprofessionalServices = 'NonprofessionalServices',
  ReturnofPremiumLongTermCare = 'ReturnofPremiumLongTermCare',
  Cancellation = 'Cancellation',
  NonForfeitureLTC = 'NonForfeitureLTC',
  HighLimitResidentialCare = 'HighLimitResidentialCare',
  AssistedLivingRoomCharge = 'AssistedLivingRoomCharge',
  MaritalDiscount = 'MaritalDiscount',
  AdditionalCashBenefit = 'AdditionalCashBenefit',
  SurvivorshipandWaiverofPremiumBenefit = 'SurvivorshipandWaiverofPremiumBenefit',
  CalendarDayRider = 'CalendarDayRider',
  SpouseSecurityBenefit = 'SpouseSecurityBenefit',
  AccidentalFracture = 'AccidentalFracture',
  CatchUpRider = 'CatchUpRider',
  HealthworkersProtectionRider = 'HealthworkersProtectionRider',
  HIVRider = 'HIVRider',
  DisabilityLoanProtection = 'DisabilityLoanProtection',
  LifetimeBenefitPresumptiveDisability = 'LifetimeBenefitPresumptiveDisability',
  RetirementProtectionRider = 'RetirementProtectionRider',
  RetirementBuilderBenefit = 'RetirementBuilderBenefit',
  TaxLiabilityRider = 'TaxLiabilityRider',
  CompassionateLeaveBenefit = 'CompassionateLeaveBenefit',
  SaleofBusinessFacilitation = 'SaleofBusinessFacilitation',
  ExpenseCoverageduringEliminationPeriod = 'ExpenseCoverageduringEliminationPeriod',
  AccidentalDeathBenefit = 'AccidentalDeathBenefit',
  Other = 'Other'
}

export enum RiskCalculationType {
  Default = 'Default',
  SmartMatch = 'SmartMatch'
}

export enum RiskCalculationStrategy {
  Fidx = 'Fidx',
  Envestnet = 'Envestnet'
}

export enum SelectionRuleType {
  Unknown = 'Unknown',
  RiderOptionalDefaultValueOfRiderOptionAllowed = 'RiderOptionalDefaultValueOfRiderOptionAllowed',
  RiderOptionalUserSelectionOfRiderOptionRequired = 'RiderOptionalUserSelectionOfRiderOptionRequired',
  RiderMandatoryDefaultValueOfRiderOptionAllowed = 'RiderMandatoryDefaultValueOfRiderOptionAllowed',
  RiderMandatoryUserSelectionOfRiderOptionRequired = 'RiderMandatoryUserSelectionOfRiderOptionRequired',
  ElectionIsOptional = 'ElectionIsOptional',
  ElectionIsAutomaticMandatory = 'ElectionIsAutomaticMandatory',
  RiderMandatoryUserSelectionIsNotallowed = 'RiderMandatoryUserSelectionIsNotAllowed',
  Other = 'Other'
}

export enum SurrenderChargeType {
  Unknown = 'Unknown',
  StandardSurrenderCharge = 'StandardSurrenderCharge',
  RollingSurrenderCharge = 'RollingSurrenderCharge',
  NoSurrenderCharge = 'NoSurrenderCharge',
  Other = 'Other'
}

export enum TransactionApprovalStatusType {
  Approved = 'Approved',
  Declined = 'Declined',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  PrePending = 'PrePending'
}
